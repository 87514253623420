// Self created custom UIkit
@import "ui-elements.scss";

// Bootstrap grid system
@import "bootstrap-grid.min.css";
@import "nz-upload";

// Font Montserrat
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.questions-group {
    .ant-checkbox-group-item {
        display: block;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}

.ant-menu-submenu-popup {
    ul {
        max-height: 400px;
        overflow: auto;
    }
}
.ant-layout-sider-trigger {
    background: transparent;
}
.collapsed {
    .ant-layout-sider-trigger {
        background: #288eab;
    }
}
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    display: flex;
    align-items: center;
}
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
    i {
        cursor: not-allowed;
    }
}
.ant-form-item-label > label {
    font-size: 16px;
}
// .ant-layout-sider-trigger {
//     // width: initial !important;
//     background: transparent;
//     height: 66px;
//     left: 80px;
//     top: 50%;
//     transform: translateY(-50%);
// }

.upload-required {
    .ant-upload {
        border-color: #ff4d4f !important;
        .anticon,
        .ant-upload-text {
            color: #ff4d4f !important;
        }
    }
}
.association-logo-modal {
    .ant-modal-footer {
        .ant-btn:not(.ant-btn-primary) {
            display: none;
        }
        .ant-btn-primary {
            border-radius: 4px;
            height: 100%;
        }
    }
}
.modal-footer-btns {
    .ant-modal-footer {
        .ant-btn {
            border-radius: 4px;
            height: 100%;
        }
    }
}

.delete-confirm-modal {
    .ant-modal-confirm-body {
        .ant-modal-confirm-content {
            margin-top: 0;
        }
    }
    .ant-btn {
        border-radius: 4px;
        height: 100%;
    }
}

.ant-page-header {
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12);
}

.cdk-global-scrollblock {
    position: static;
    // overflow: hidden !important;
}

.note {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
}

.cdk-drag-dragging .menu-collapse-panel {
    background: #ff4d4f;
}
.not_warning {
    position: fixed;
    left: 50%;
    transform: translateX(-50%) !important;
    background: #ff4d4f;
    color: white;
}

// Froala default font

.fr-view {
  font-family: 'Arial Narrow Bold', sans-serif;
}
