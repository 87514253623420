body {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    background: #e5e5e5;
}

.page-wrapper {
    background: #ffffff;
    border-radius: 6px;
    margin: 24px;
    padding: 24px;
}

.primary-button {
    border-radius: 4px;
    height: 100%;
    font-weight: 500;
    line-height: 22px;
}

.transparent-button {
    background: transparent;
    border: none;
}

.primary-input {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px !important;
    padding: 8px;
    font-size: 16px;
    line-height: 20px;
}

.invalid-field {
    background-color: #fff;
    border-color: #ff4d4f !important;
    input {
        background-color: #fff;
        border-color: #ff4d4f !important;
    }
}

.invalid-field-message,
.invalid-field-message:hover {
    color: #ff4d4f;
    font-size: 14px;
}

.loading-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 9999;
    .ant-spin-text {
        padding-top: 10px;
        left: -30px;
    }
}

.action-icon {
    font-size: 16px;
    display: block;
    cursor: pointer;
    &.delete {
        color: #ff4d4f !important;
    }
    &.primary {
        color: #288eab !important;
    }
}

.basic-table {
    .ant-table-thead > tr > th {
        background: rgba(40, 142, 171, 0.2);
        font-weight: bold;
        font-size: 16px;
    }
    .highlighted.ant-table-cell {
        color: rgba(40, 142, 171, 1);
        font-size: 16px;
        font-weight: bold;
    }
    .ant-table-cell {
        color: rgba(0, 0, 0, 1);
    }
    .add-icon {
        font-size: 16px;
        color: rgba(40, 142, 171, 1);
        cursor: pointer;
    }
    .visible-icon {
        font-size: 16px;
        cursor: pointer;
        color: #288eab;
    }
}

.color-picker {
    width: 135px;
}

.cursor-pointer {
    cursor: pointer;
}

.primary-color {
    color: #288eab !important;
}

.no-wrap {
    white-space: nowrap !important;
}

.required-field {
    &:before {
        display: inline-block;
        margin-right: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: "*";
    }
}

.disabled-upload {
    opacity: 0.4;
    button {
        cursor: default !important;
    }
}

.visible-button {
    font-size: 16px;
    padding-left: 0;
    padding-right: 0;
}

.froala-instance {
    &.validation-error {
        .fr-toolbar.fr-top,
        .fr-wrapper.show-placeholder,
        .fr-second-toolbar {
            border-color: #ff4d4f;
        }
        .fr-wrapper.show-placeholder {
            border-bottom-color: initial;
        }
    }
}
