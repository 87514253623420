nz-upload.heading-style-upload-image-container {
  .ant-upload-drag-container {
    position: absolute;
    left: 20px;
    top: 5px;
  }
  .ant-upload{
    position: absolute;
    width: 191px;
    height: 120px;
    top: 10px;
    right: 10px;
    z-index: 9;
  }
}
nz-upload.no-padding {
  .ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
  }
}
